.overflow_a {
	max-height: 600px;
	overflow-y: auto;
	overflow-x: hidden;
}
.add_pics_inside1 {
	position: relative;
	border-radius: 10px;
	padding: 10px;
	height: 250px;
	display: grid;
	place-items: center;
	cursor: pointer;
}
.p0 {
	padding: 0;
	border-radius: 10px;
	height: 100%;
	cursor: default;
	overflow: hidden;
}
.preview_actions {
	position: absolute;
	left: 1rem;
	top: 1rem;
	display: flex;
	gap: 1rem;
}

.small_white_circle {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	border: 1px solid var(--bg-third);
	background: var(--bg-primary);
	display: grid;
	place-items: center;
	position: absolute;
	right: 10px;
	top: 10px;
	cursor: pointer;
	z-index: 99;
}

.preview1 {
	width: 100%;
	height: 100%;
}
.preview1 img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.preview2 {
	height: 100%;
	display: flex;
	gap: 2px;
}
.preview2 img {
	width: 50%;
	height: 230px;
	object-fit: cover;
}
.preview3 {
	height: 100%;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(2, 1fr);
	gap: 2px;
}
.preview3 img {
	width: 100%;
	height: 200px;
	object-fit: cover;
}
.preview3 img:nth-child(1) {
	grid-column: 1/3;
}
.preview4 {
	height: 100%;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(2, 1fr);
	gap: 2px;
}
.preview4 img {
	width: 100%;
	height: 200px;
	object-fit: cover;
}
.preview4 img:nth-child(1) {
	grid-column: 1/4;
}
.preview5 {
	height: 100%;
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-template-rows: repeat(2, 1fr);
	gap: 2px;
}
.preview5 img {
	width: 100%;
	height: 200px;
	object-fit: cover;
	grid-column: auto / span 2;
}
.preview5 img:nth-child(1),
.preview5 img:nth-child(2) {
	grid-column: auto / span 3;
}
.preview6 {
	height: 100%;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 2px;
}
.preview6 img {
	width: 100%;
	height: 200px;
	object-fit: cover;
}
.singular_grid img:nth-child(1) {
	grid-column: 1/3;
}

/*  */
.Image {
	border-radius: 10px;
}
/* Posts*/

.posts {
	margin-top: 1rem;
	width: 100%;
}
.post {
	position: relative;
	width: 685px;
	background: var(--bg-primary);
	border-radius: 10px;
	box-shadow: 0 1px 2px var(--shadow-1);
	user-select: none;
	color: var(--color-primary);
	margin-bottom: 10px;
}
.post_header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 15px;
}
.post_header_left {
	display: flex;
	align-items: center;
	gap: 10px;
}
.post_header_left img {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	object-fit: cover;
}
.header_col {
	display: flex;
	flex-direction: column;
}
.post_profile_name {
	display: flex;
	align-items: center;
	gap: 5px;
	font-size: 14px;
	font-weight: 600;
	line-height: 19px;
}
.updated_p {
	color: var(--color-secondary);
	font-weight: normal;
}
.post_profile_privacy_date {
	margin-top: 1px;
	display: flex;
	align-items: center;
	font-size: 12px;
	color: var(--color-secondary);
}
.post_bg {
	height: 320px;
	padding: 15px;
	display: grid;
	place-items: center;
	font-weight: 600;
	font-size: 22px;
	background-repeat: no-repeat;
	background-size: cover;
}
.post_text {
	padding: 0 15px 10px 15px;
}

/*-----------------------------GRID IMAGES----------------*/
.grid_1 img {
	width: 100%;
	height: 320px;
	object-fit: cover;
}
.grid_1 {
	display: flex;
	height: 320px;
	justify-content: center;
	/* background-color: #000000; */
}
.grid_2 {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr;
	background: #fff;
	gap: 1px;
}
.grid_2 img:first-of-type {
	width: 100%;
	grid-column-start: 1;
	grid-column-end: 2;
	height: 320px;
	object-fit: cover;
}
.grid_2 img:last-of-type {
	width: 100%;
	grid-column-start: 2;
	grid-column-end: 3;
	height: 320px;
	object-fit: cover;
}
.grid_3 {
	width: 100%;
	display: grid;
	grid-template-columns: 1.4fr 1fr;
	grid-template-rows: 1fr 1fr;
	background: #fff;
	gap: 1px;
}
.grid_3 img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.grid_3 .img-0 {
	width: 100%;
	grid-column-start: 1;
	grid-column-end: 2;
	grid-row-start: 1;
	grid-row-end: 3;
	height: 320px;
	object-fit: cover;
}
.grid_3 .img-1 {
	width: 100%;
	grid-column-start: 2;
	grid-column-end: 3;
	grid-row-start: 1;
	grid-row-end: 2;
	height: 320px;
	object-fit: cover;
}
.grid_3 .img-2 {
	width: 100%;
	grid-column-start: 2;
	grid-column-end: 3;
	grid-row-start: 2;
	grid-row-end: 3;
	height: 320px;
	object-fit: cover;
}
.grid_4 {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	background: #fff;
	gap: 1px;
}
.grid_4 img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.grid_4 .img-0 {
	width: 100%;
	grid-column-start: 1;
	grid-column-end: 4;
	grid-row-start: 1;
	grid-row-end: 2;
	height: 320px;
	object-fit: cover;
}
.grid_4 .img-1 {
	width: 100%;
	grid-column-start: 1;
	grid-column-end: 2;
	grid-row-start: 2;
	grid-row-end: 3;
	height: 320px;
	object-fit: cover;
}
.grid_4 .img-2 {
	width: 100%;
	grid-column-start: 2;
	grid-column-end: 3;
	grid-row-start: 2;
	grid-row-end: 3;
	height: 320px;
	object-fit: cover;
}
.grid_4 .img-3 {
	width: 100%;
	grid-column-start: 3;
	grid-column-end: 4;
	grid-row-start: 2;
	grid-row-end: 3;
	height: 320px;
	object-fit: cover;
}
.grid_5 {
	position: relative;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-template-rows: 1fr 1fr;
	background: #fff;
	gap: 1px;
}
.grid_5 img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.grid_5 .img-0 {
	width: 100%;
	grid-column: auto / span 3;
	grid-row-start: 1;
	grid-row-end: 2;
	height: 320px;
	object-fit: cover;
}
.grid_5 .img-1 {
	width: 100%;
	grid-column: auto / span 3;

	grid-row-start: 1;
	grid-row-end: 2;
	height: 320px;
	object-fit: cover;
}
.grid_5 .img-2 {
	width: 100%;
	grid-column: auto / span 2;

	grid-row-start: 2;
	grid-row-end: 3;
	height: 320px;
	object-fit: cover;
}
.grid_5 .img-3 {
	width: 100%;
	grid-column: auto / span 2;

	grid-row-start: 2;
	grid-row-end: 3;
	height: 320px;
	object-fit: cover;
}
.grid_5 .img-4 {
	width: 100%;
	grid-column: auto / span 3;
	grid-row-start: 2;
	grid-row-end: 3;
	height: 320px;
	object-fit: cover;
}

.more-pics-shadow {
	position: absolute;
	bottom: 0;
	right: 0;
	height: 200px;
	width: 33.33%;
	background: #94949454;
	font-size: 32px;
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	color: #fff;
}
.post_infos {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
}
.reacts_count {
	display: flex;
	align-items: center;
	gap: 5px;
}
.reacts_count_imgs {
	display: flex;
	align-items: center;
	gap: 1px;
}
.reacts_count_imgs img {
	width: 18px;
}
.reacts_count_num {
	font-size: 14px;
	color: var(--color-secondary);
}
.to_right {
	display: flex;
	align-items: center;
	gap: 10px;
	color: var(--color-secondary);
	font-size: 15px;
}
.comments_count:hover,
.share_count:hover {
	cursor: pointer;
	text-decoration: underline;
}
.post_actions {
	position: relative;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	padding: 0 8px 5px 8px;
	border-top: 1px solid var(--bg-third);
	border-bottom: 1px solid var(--bg-third);
	margin: 0 10px;
	padding: 5px 0;
}
.post_action {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	cursor: pointer;
	padding: 7px 0;
	border-radius: 5px;
}
.post_action span {
	color: var(--color-secondary);
	font-weight: 600;
	font-size: 14px;
	transform: translateY(-1px);
}
.reacts_popup {
	display: flex;
	align-items: center;
	position: absolute;
	top: -3.4rem;
	background: var(--bg-primary);
	padding: 5px 15px;
	border-radius: 50px;
	gap: 8px;
	box-shadow: 0 1px 2px var(--shadow-1);
}
.react {
	cursor: pointer;
	transition: all 0.2s;
}
.react:hover {
	transform: scale(1.3);
}
.react img {
	width: 42px;
	height: 42px;
	object-fit: cover;
}

/*-----create comment-----*/
.comments_wrap {
	padding: 10px 15px;
}
.create_comment {
	display: flex;
	align-items: center;
	gap: 5px;
}
.create_comment img {
	width: 35px;
	height: 35px;
	border-radius: 50%;
	object-fit: cover;
}
.comment_input_wrap {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	background: var(--bg-forth);
	border-radius: 50px;
	padding-right: 10px;
}
.comment_input_wrap input {
	outline: none;
	border: none;
	flex: 1;
	height: 35px;
	padding: 0 10px;
	color: var(--color-primary);
	background: transparent;
}
.comment_circle_icon {
	height: 30px;
	width: 30px;
	border-radius: 50%;
	display: grid;
	place-items: center;
	cursor: pointer;
}
.comment_error {
	padding: 0 !important;
	opacity: 1 !important;
}
.comment_img_preview {
	position: relative;
	margin-top: 5px;
	margin-left: 40px;
	width: 160px;
}
.comment_img_preview img {
	width: 150px;
	height: 100px;
	object-fit: cover;
	border-radius: 5px;
}
.comment_img_preview .small_white_circle {
	top: -6px;
	right: -4px;
	transform: scale(0.8);
}
/*-----create comment-----*/
/*------Post menu------*/
.post_menu {
	background: var(--bg-primary);
	width: 300px;
	box-shadow: 1px 0 2px var(--shadow-1);
	border-radius: 10px;
	position: absolute;
	top: 3.1rem;
	right: 10px;
	z-index: 99;
	font-family: inherit;
	font-size: 14px;
	line-height: 19px;
	padding: 10px;
}
.post_menu li {
	display: flex;
	align-items: center;
	gap: 10px;
	font-weight: 600;
	padding: 10px;
	cursor: pointer;
	border-radius: 10px;
}
.post_menu_text {
	display: flex;
	flex-direction: column;
}
.menu_post_col {
	font-size: 12px;
	color: #65676b;
}
.line {
	width: 100%;
	height: 1px;
	background: #ced0d4;
	margin: 5px 0;
}
/*------Post menu------*/
/* @media (max-width: 415px) {
  .post .grid_2 img,
  .post .grid_3 img,
  .post .grid_4 img,
  .post .grid_5 img {
    height: 240px !important;
  }
  .post_bg {
    height: 400px !important;
  }
  .post .grid_1 {
    height: 100% !important;
  }
  .post .grid_1 img {
    height: 450px !important;
  }
} */
